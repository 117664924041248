export enum StepType {
    DataSearch = 'dataSearch',
    AIOperation = 'AIOperation',
    InputStep = 'inputStep',
    OutputStep = 'outputStep',
    PythonStep = 'pythonStep',
    ExecutePipelineStep = 'executePipelineStep',
    MemoryLoadStep = 'memoryLoadStep',
    MemoryStoreStep = 'memoryStoreStep',
}

export enum DataSourceType {
    AZURE_BLOB = 'AzureBlob',
    SCALE_FLEX = 'ScaleFlex',
    CLOUD_KIT = 'CloudKit',
    FILE_UPLOAD = 'FileUpload',
    UNKNOWN = 'Unknown',
}

export type Memory = {
    id: string
    version: string
    name: string
    storageLocation: string
    createdAt: string
    updatedAt: string
    projectId: string
}

export type InMemoryMessage = {
    role: string
    message: string
}

export type BaseStepData = {
    id: string
    position: {
        id?: string
        x: number
        y: number
    }
    dependencies: string[]
    projectId?: string
    pipelineId: string
}

export type InputStepData = BaseStepData & {
    stepType: StepType.InputStep
}

export type OutputStepData = BaseStepData & {
    stepType: StepType.OutputStep
}

export type ModelStepData = BaseStepData & {
    stepType: StepType.AIOperation
    prompt: string | null
    temperature: number
    includeContext: boolean
    modelId: string
    selectedPromptVersion: string | null
}

export type DatasetStepData = BaseStepData & {
    stepType: StepType.DataSearch
    dataSource: string
    topK: string
    relevanceThreshold: string
    dataSourceType: string
}

export type PipelineStepData = BaseStepData & {
    stepType: StepType.ExecutePipelineStep
    executePipelineId: string
    selectedPipelineVersion: string
    versionNumber: number
}

export type PythonCodeStepData = BaseStepData & {
    stepType: StepType.PythonStep
    code: string
}

export type MemoryStepData = BaseStepData & {
    stepType: StepType.MemoryLoadStep | StepType.MemoryStoreStep
    memory: string
} & Memory

export type PipelineStep =
    | InputStepData
    | OutputStepData
    | ModelStepData
    | DatasetStepData
    | PipelineStepData
    | PythonCodeStepData
    | MemoryStepData

export type PipelineVersion = {
    id: string
    pipelineId: string
    versionNumber: string
    majorVersion: number,
    minorVersion: number,
    isLatest: boolean
    steps: PipelineStep[]
}

export type Pipeline = {
    id: string
    description: string
    name: string
    executionName: string
    createdAt: string
    updatedAt: string
    activeVersionId: string
    activeVersion: PipelineVersion
    versions: PipelineVersion[]
    executionStats: {
        successCount: number
        failureCount: number
    }
    projectId: string
}

export type TemporaryPipelineRequest = {
    pipeline: Pipeline
    userInput: string
    conversationId: string
    userId?: string
    saveHistory?: boolean
    asyncOutput: boolean
    temporaryPrompt?: string
    debug: boolean
    images?: string[]
    inMemoryMessages?: InMemoryMessage[]
}